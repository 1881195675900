import React from "react"
import MediaPost from "./media-post";
import Pagination from "../common/pagination";

const MediaFeed = ({data, pageContext}) => (
    <section className="position-relative py-8 py-md-11 mb-9">
        {/** Content */}
        <div className="container">
            {/** Heading */}
            <h1 className="display-4 font-weight-bold spacing title-feed">
                Prensa
                <br/><br/>
            </h1>

            <div className="row align-items-center">

                {data.allStrapiNotice.edges.map((document, i) => (
                    <MediaPost mediaPost={document.node} key={i}/>
                ))}

            </div>
            {/** / .row */}

            <Pagination pageContext={pageContext}/>

        </div>
        {/** / .container */}

    </section>
)

export default MediaFeed
