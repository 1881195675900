import React from "react"
import SEO from "../components/common/seo";
import MediaFeed from "../components/media/media-feed";
import {graphql} from "gatsby";
import LayoutSecondary from "../components/common/layout-secondary";
import {Link} from "@reach/router";

const MediaPage = ({data, pageContext}) => {
    let title = "Zona de prensa - Tecalis";
    let metaDescription = "Consulta las notas de prensa y noticias de Tecalis en los prrincipales medios de comunicación especializados de todo el mundo."

    if (pageContext.currentPage > 1) {
        title = `Zona de prensa - Página ${pageContext.currentPage} - Tecalis`;
        metaDescription += ` Página ${pageContext.currentPage}`;
    }

    return (

        <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
            <SEO title={title} description={metaDescription}/>
            <MediaFeed data={data} pageContext={pageContext}/>

            <section className="position-relative py-8 py-md-11 mb-0 border-top bg-grey">

                <div className="container">

                    {/* Heading */}
                    <h3 className="display-5 font-weight-bold spacing">
                        Listado de noticias
                        <br/><br/>
                    </h3>

                    {/* Card */}
                    <div className="card shadow-light-lg accordion mb-5 mb-md-6" id="helpAccordionOne">
                        <div className="list-group">
                            {data.allStrapiPost && data.allStrapiPost.edges.map((document, i) => {

                                return (
                                    <div className="list-group-item" key={i}>

                                        {/* Header */}
                                        <div className="d-flex align-items-center">
                                            <div className="mr-auto">
                                                {/* Heading */}
                                                <h4 className="font-weight-regular mb-0 spacing">
                                                    <Link to={document.node.fields.slug}>{document.node.title}</Link>
                                                </h4>
                                            </div>

                                            {/* Fecha */}
                                            <span className="badge badge-pill badge-success-soft ml-4">
                                          <span className="h6 text-uppercase">
                                            {document.node.publish_date}
                                          </span>
                                        </span>
                                        </div>

                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>

            </section>

            {/** TECALIS LOGOS / DESCARGAS
             ================================================== */}
            <section className="pt-10 pb-1 bg-white border-top">
                <div className="container">

                    <div className="row mb-7 mb-md-9">
                        <div className="col-12 col-md-6 text-center">

                            {/** Heading */}
                            <h6 className="text-uppercase text-gray-700 mb-1">
                                Logotipo
                            </h6>

                            {/** Link */}
                            <div className="mb-5 mb-md-0">
                                {/** Text */}
                                <p className="font-size-lg text-black-80 mb-6 spacing">
                                    <a href={"/resources/logos-tecalis.zip"} className="underlines-tecalis font-weight-bold"> Descarga</a>
                                </p>
                            </div>

                        </div>
                        <div className="col-12 col-md-4 text-center border-left-md border-gray-300">

                            {/** Heading */}
                            <h6 className="text-uppercase text-gray-700 mb-1">
                                Dossier fotográfico
                            </h6>

                            {/** Link */}
                            <div className="mb-5 mb-md-0">
                                {/** Text */}
                                <p className="font-size-lg text-black-80 mb-6 spacing">
                                    <a href={"/resources/press-images.zip"}
                                       className="underlines-tecalis font-weight-bold"> Descarga</a>
                                </p>
                            </div>

                        </div>
                    </div>
                    {/** / .row */}

                </div>
            </section>

        </LayoutSecondary>
    );
}

export const mediaPageQuery = graphql`
  query($limit: Int!, $skip: Int!, $lang: String!, $dateFormat: String!) {
        allStrapiPost(
            sort: { fields: [ publish_date], order: [DESC] }
            limit: 8
            skip: 0
            filter: {
                locale: { eq: $lang },
                add_to_press: {
                        eq: true
                }
        }){
            edges {
                node {
                    id
                    title
                    description
                    publish_date(formatString: $dateFormat, locale: $lang)
                    fields{
                        slug
                    }
                }
            }
        }
        allStrapiNotice(
            sort: { 
                fields: [id], 
                order: [DESC] 
            }
            limit: $limit
            skip: $skip
            filter: {
                locale: { eq: $lang }
            }
            ) {
            edges {
                node {
                    title
                    description
                    link
                    media{
                        media {
                            name
                            localFile {
                                publicURL
                                childImageSharp {
                                    fluid(maxWidth: 720, base64Width: 720) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
         cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
    }
`;

export default MediaPage
