import React from "react"

const MediaPost = ({mediaPost}) => {

    return (
        <div className="col-xl-4">
            {/** Only Card */}
            <div className="card card-row border-card mb-6 card--medios">

                <a className="card-body my-auto" href={mediaPost.link} target="_blank" rel="noopener noreferrer">
                    <p className="mb-0 text-muted spacing">
                        {mediaPost.description}
                    </p>
                </a>

                <div className="card-meta">
                    <hr className="card-meta-divider"/>

                    {/** Logo media press */}
                    <h6 className="text-uppercase text-muted mb-0">
                        <div align="center">
                            { mediaPost.media && mediaPost.media.media &&
                            <img src={mediaPost.media.media.localFile.childImageSharp.fluid.src} width="180" alt={mediaPost.media.media.name}
                                 className="logo-media img-fluid"/>
                            }
                        </div>
                    </h6>

                    {/** Link a prensa*/}
                    <div className="text-muted mb-0 ml-auto">
                        <a href={mediaPost.link} target="_blank" rel="noopener noreferrer">
                            <i className="fe fe-arrow-right color-tecalis"/>
                        </a>
                    </div>
                </div>

            </div>
            {/** ./ card */}
        </div>
    )
}

export default MediaPost
